<style scoped>
</style>

<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="name">名称</Option>
                </Select>
              </i-input>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          <Button v-if="$authFunsProxyNoRoute['scm.produce.in_order-get']" style="margin-left: 10px;" @click="goClick" type="primary">入库单编辑器</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:accordingType="{ row }">
          生产入库
        </template>
        <template v-slot:planTime="{ row }">
          {{row.planTime ? row.planTime.slice(0, 10) : '-'}}
        </template>
        <template v-slot:status="{ row }">
          <PlanStatus :status="row.exeStatus" />
        </template>
        <template v-slot:endTime="{ row }">
          {{row.endTime ? row.endTime.slice(0, 10) : '-'}}
        </template>
        <template v-slot:action="{ row }">
          <Button @click="detail(row)" type="text" size="small">明细</Button>
        </template>
      </Table>
    </template>
    <template v-slot:footer>
      <Drawer placement="right" title="出库单明细" :mask-closable="false" closable transfer draggable :width="80" v-model="status.detail">
        <OutorderList :key="chooseItem.id" v-if="chooseItem && status.showDetail" :outOrderGroupId="chooseItem.id" style="flex: 1; width: 0;height: 100%;margin-top: 0;padding-top: 0;margin-bottom: 0;padding-bottom: 0;padding-left: 0;padding-right: 0;margin-right: 0;" />
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import request from '../../api/index'
import PlanStatus from '../../components/plan/status.vue'
import OutorderList from './outorderList.vue'

export default {
  components: { AppPage,PlanStatus,OutorderList },
  data () {
    return {
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {
        key: 'name',
        value: null
      },
      loading: {
        load: false,
        submit: false,
      },
      dataList: [],
      status: {
        form: false,
        detail: false,
        showDetail: false
      },
      chooseItem: null
    }
  },
  computed: {
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    columns () {
      let data = [
        { title: '来源', key: 'accordingType', slot: 'accordingType' },
        { title: '名称', key: 'name' },
        { title: '入库仓库', key: 'storehouseName', minWidth: 100 },
        { title: '计划入库时间', key: 'planTime', slot: 'planTime' },
        { title: '完成时间', key: 'endTime', slot: 'endTime'},
        { title: '状态', key: 'status', slot: 'status' },
        { title: '备注', key: 'remark'}
      ]
      if (this.$authFunsProxyNoRoute['scm.produce.in_order_list-get']) {
        data.push({ title: '操作', key: 'action', slot: 'action', width: 80 })
      }
      return data
    }
  },
  methods: {
    detail (item) {
      this.chooseItem = item
      this.status.detail = true
      this.$router.push({name: 'scm.produce.in_order_list', query: {
        groupId: this.chooseItem.id,
        groupName: this.chooseItem.name
      }})
    },
    goClick () {
      this.$router.push({name: 'scm.produce.in_order'})
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'name'
      this.query.value = null
      this.query.status = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock_in_order/group', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))
        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    }
  }
}
</script>
